document.addEventListener('DOMContentLoaded', function () {
    var forms = document.querySelectorAll('form');

    forms.forEach(function (form) {
        var inputs = form.querySelectorAll('input[required], textarea[required]');
        var submitBtn = form.querySelector('button[type="submit"], input[type="submit"]');

        function checkForm() {
            var isValid = true;
            inputs.forEach(function (input) {
                if (!input.value.trim()) {
                    isValid = false;
                }
            });

            if (isValid) {
                submitBtn.classList.add('valid');
            } else {
                submitBtn.classList.remove('valid');
            }
        }

        inputs.forEach(function (input) {
            input.addEventListener('input', checkForm);
        });
    });
});


document.addEventListener('DOMContentLoaded', function () {
    var fileWrappers = document.querySelectorAll('.file-input-wrapper');

    fileWrappers.forEach(function (wrapper) {
        var pElement = wrapper.querySelector('p');
        var fileInput = wrapper.querySelector('input[type="file"]');

        fileInput.addEventListener('change', function () {
            if (fileInput.files.length > 0) {
                var fileNames = Array.from(fileInput.files).map(file => file.name);
                pElement.textContent = fileNames.join(" / ");
            } else {
                pElement.textContent = "Choose a photo";
            }
        });
    });
});


document.addEventListener('DOMContentLoaded', function () {
    var modalBg = document.querySelector('.modal-bg');
    var modal = document.querySelector('.modal');
    var closeButton = document.querySelector('.close-button');
    var sessionKey = 'modalShown';
    var timerKey = 'modalTimerStart';
    var delay = 45;

    function openModal() {
        modalBg.classList.add('active');
        modal.classList.add('active');
        sessionStorage.setItem(sessionKey, 'true');
    }

    function closeModal() {
        modalBg.classList.remove('active');
        modal.classList.remove('active');
    }

    function checkAndStartTimer() {
        if (sessionStorage.getItem(sessionKey)) return;

        var startTime = sessionStorage.getItem(timerKey);
        var now = Math.floor(Date.now() / 1000);

        if (!startTime) {
            sessionStorage.setItem(timerKey, now);
            startTime = now;
        }

        var elapsedTime = now - startTime;

        if (elapsedTime >= delay) {
            openModal();
        } else {
            setTimeout(openModal, (delay - elapsedTime) * 1000);
        }
    }

    modalBg.addEventListener('click', closeModal);
    closeButton.addEventListener('click', closeModal);

    checkAndStartTimer();
});
